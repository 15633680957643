import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-norman-oklahoma.png'
import image0 from "../../images/cities/scale-model-of-james-garner-statue-in-norman-oklahoma.png"
import image1 from "../../images/cities/scale-model-of-june-benson-park-in-norman-oklahoma.png"
import image2 from "../../images/cities/scale-model-of-jacobson-house-in-norman-oklahoma.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Norman'
            state='Oklahoma'
            image={image}
            lat='35.2225668'
            lon='-97.4394777'
            attractions={ [{"name": "James Garner statue", "vicinity": "Downtown, Norman", "types": ["point_of_interest", "establishment"], "lat": 35.2207608, "lng": -97.44336570000002}, {"name": "June Benson Park", "vicinity": "209 E Alameda St, Norman", "types": ["park", "point_of_interest", "establishment"], "lat": 35.2186783, "lng": -97.43965659999998}, {"name": "Jacobson House", "vicinity": "609 Chautauqua Ave, Norman", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.2107755, "lng": -97.44987409999999}] }
            attractionImages={ {"James Garner statue":image0,"June Benson Park":image1,"Jacobson House":image2,} }
       />);
  }
}